/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import usePermissions from "../../../../components/permissions/permission";
import axios from "axios";

const TableInsideCard = ({ navigate }) => {
  const { hasPermission } = usePermissions();
  const [tableData, setTableData] = useState([]);
  const [approvePR, setApprovePR] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;

  useEffect(() => {
    const fetchApprovePR = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/supplychain/purcaseRequestApprove/for-chairman-approval`
        );
        const data = await response.json();
        setApprovePR(data);
        console.log("approve data", data);
      } catch (error) {
        console.error("Error fetching designations:", error);
      }
    };

    fetchApprovePR();
  }, []);

  const handleViewClick = (item) => {
    navigate("/purchase/viewPurchaseRequestChairman", {
      state: { selectedRecord: item },
    });
  };

  const getButtonStyle = (status) => {
    switch (status) {
      case "APPROVED":
        return "bg-approveStat text-approveStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0";
      case "REJECTED":
        return "bg-rejectedStat text-rejectedStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0";
      case "PENDING":
        return "bg-pendingStat text-pendingStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0";
      default:
        return ""; // Default style
    }
  };

  const getButtonStyleStatus = (decision) => {
    switch (decision) {
      case "APPROVED":
        return "bg-approveStat text-approveStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0";
      case "REJECTED":
        return "bg-rejectedStat text-rejectedStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0";
      case "PENDING":
        return "bg-pendingStat text-pendingStattxt border-none rounded-[9px] w-[139px] h-[33px] flex-shrink-0";
      default:
        return ""; // Default style
    }
  };

  return (
    <div className="bg-[#FFF] rounded-[20px] p-4">
      {approvePR.length > 0 ? (
        <table className="w-full border-collapse border-none">
          <thead>
            <tr className="text-left bg-[#FFF]">
              <th className="px-4 py-2 font-normal">PR No</th>
              <th className="px-4 py-2 font-normal">Request Title</th>
              <th className="px-4 py-2 font-normal">Request Date</th>
              <th className="px-4 py-2 font-normal">Department</th>
              <th className="px-4 py-2 font-normal">SM Approve Status</th>
              <th className="px-4 py-2 font-normal">Decision</th>
              <th className="px-4 py-2 font-normal">Action</th>
            </tr>
          </thead>
          <tbody className="font-bold">
            {approvePR.map((item, index) => (
              <tr key={index}>
                <td className="px-4 py-2">{item.pr_id}</td>
                <td className="px-4 py-2">{item.title}</td>
                <td className="px-4 py-2">{item.pr_date}</td>
                <td className="px-4 py-2">{item.department_name}</td>
                <td className="px-4 py-2">
                  <button
                    className={`inline-block ${getButtonStyleStatus(
                      item.pr_supmanager_ap_stauts
                    )}`}
                  >
                    {item.pr_supmanager_ap_stauts}
                  </button>
                </td>
                <td className="px-4 py-2">
                  <button
                    className={`inline-block ${getButtonStyle(
                      item.pr_chairman_ap_status
                    )}`}
                  >
                    {item.pr_chairman_ap_status}
                  </button>
                </td>
                <td className="px-4 py-2">
                  {hasPermission(2610) && (
                    <button
                      className="text-approveStattxt hover:underline"
                      onClick={() => handleViewClick(item)}
                    >
                      View
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="text-center text-gray-500 font-semibold">
          No data available
        </div>
      )}
    </div>
  );
};

export default TableInsideCard;
