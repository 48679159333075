import React, { useEffect, useRef, useState } from "react";
import { IoAdd } from "react-icons/io5";
import { FaEdit, FaTrash } from "react-icons/fa"; // Import icons for edit and delete
import axios from "axios";
// import AddVehiclePopup from "./add_vehicle_popup";
// import Edit_chat_account_popup from "./edit_chat_account_popup";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import { Link } from "react-router-dom";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import AddVehiclePartPopup from "./add_vehicle_part_popup";
import Edit_vehicle_part_popup from "./edit_vehicle_part_popup";
import Navbar from "../../../../components/navbar/navbar";

function Vehicle_part_details() {
  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;
  const [seletedValue, setSeletedValue] = useState({
    search_term: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState({ open: false, data: {} });
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default rows per page
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [pagination, setPagination] = useState({
    current_page: 1,
    page_size: 20,
    total: 0,
    total_pages: 0,
  });

  const handleRowSelect = (id) => {
    console.log(id);
    if (selectedRow === id) {
      setSelectedRow(null);
      setDropdownVisible(false);
    } else {
      setSelectedRow(id);
      setDropdownVisible(true);
    }
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
    getTableData();
  };

  const toggleEditPopup = (vehicleItem) => {
    setIsOpenEdit({ open: !isOpenEdit.open, data: vehicleItem });
    getTableData();
  };

  const handleEdit = () => {
    console.log("Edit row: ", selectedRow);
    setIsOpenEdit(true);
  };

  const handleDelete = async () => {
    console.log("Delete row: ", selectedRow);
    try {
      const response = await axios.put(
        `${backendUrl}/account/getFirstAndSecondLevelAccTypes`
      );
    } catch (error) {
      console.error("Error fetching sample IDs:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setSeletedValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDeleteClick = (item) => {
    // setEmployeeToDelete(item);
    setIsPopupOpen(true);
  };

  useEffect(() => {
    console.log("data getting");
    if (searchInput) searchfunction(searchInput);
    else getTableData();
  }, [searchInput]);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  // functon to search vehicle part data
  const searchfunction = async (query) => {
    try {
      const response = await axios.get(`${backendUrl}/parts/id`, {
        params: { id: query },
      });
      if (response.data) {
        setTableData([response.data]);
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching vehicle part details:", error);
    }
  };

  // Function to handle fetching table data
  const getTableData = async () => {
    try {
      const params = {
        page_size: rowsPerPage,
        page: pagination.current_page,
        search_term: seletedValue.search_term,
      };

      console.log(params);

      const response = await axios.get(`${backendUrl}/parts/all`, {
        params,
      });

      console.log("----vehicle------");
      console.log(response.data);
      console.log("----vehicle------");

      // Ensure you set both data and pagination state
      if (response.data) {
        setTableData(response.data.data); // Set the data array
        setPagination(response.data.pagination); // Set the pagination info
      } else {
        console.error("Unexpected response format:", response.data);
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching sample details:", error);
      setTableData([]);
    }
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current_page: 1, // Reset to first page whenever rows per page changes
    }));
  };

  const PdfExport = () => {
    const doc = new jsPDF();

    // Define the table columns and rows
    const columns = [
      "Part ID",
      "Vehicle ID",
      "Part Name",
      "Part Number",
      "Lifespan(km)",
      "Installation Date",
      "Installation Milage",
      "Manufactured Date",
      "Expiry Date",
      "Maintenance Date",
      "Status",
    ];

    const rows = tableData.map((row) => [
      row.id,
      row.vehicle_id,
      row.part_name,
      row.part_number,
      row.part_life_span,
      row.installation_date,
      row.installing_mileage,
      row.manufactured_date,
      row.expiry_date,
      row.maintenance_date,
      row.part_status,
    ]);

    // Add title
    doc.text("Vehicle Part Details", 14, 10);

    // Generate the PDF table
    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 20,
    });

    // Save the PDF
    doc.save("VehicleDetails.pdf");
  };

  // new pdf document with data alignments

  // const PdfExport = () => {
  //   const doc = new jsPDF();

  //   // Define the table columns and rows
  //   const columns = [
  //     { header: "Part ID", dataKey: "id" },
  //     { header: "Vehicle ID", dataKey: "vehicle_id" },
  //     { header: "Part Name", dataKey: "part_name" },
  //     { header: "Part Number", dataKey: "part_number" },
  //     { header: "Lifespan(km)", dataKey: "part_life_span" },
  //     { header: "Installation Date", dataKey: "installation_date" },
  //     { header: "Installation Milage", dataKey: "installing_mileage" },
  //     { header: "Manufactured Date", dataKey: "manufactured_date" },
  //     { header: "Expiry Date", dataKey: "expiry_date" },
  //     { header: "Maintenance Date", dataKey: "maintenance_date" },
  //     { header: "Status", dataKey: "part_status" },
  //   ];

  //   const rows = tableData.map((row) => ({
  //     id: row.id,
  //     vehicle_id: row.vehicle_id,
  //     part_name: row.part_name,
  //     part_number: row.part_number,
  //     part_life_span: row.part_life_span,
  //     installation_date: row.installation_date,
  //     installing_mileage: row.installing_mileage,
  //     manufactured_date: row.manufactured_date,
  //     expiry_date: row.expiry_date,
  //     maintenance_date: row.maintenance_date,
  //     part_status: row.part_status,
  //   }));

  //   // Add title
  //   doc.text("Vehicle Part Details", 14, 10);

  //   // Generate the PDF table with proper alignment
  //   doc.autoTable({
  //     head: [columns.map((col) => col.header)],
  //     body: rows.map((row) => Object.values(row)),
  //     startY: 20,
  //     styles: { valign: "middle" }, // Vertically center text in all cells
  //     columnStyles: {
  //       0: { halign: "center" },  // Center-align Part ID
  //       1: { halign: "center" },  // Center-align Vehicle ID
  //       2: { halign: "left" },    // Left-align Part Name
  //       3: { halign: "center" },  // Center-align Part Number
  //       4: { halign: "right" },   // Right-align Lifespan (numeric)
  //       5: { halign: "center" },  // Center-align Installation Date
  //       6: { halign: "right" },   // Right-align Installation Milage (numeric)
  //       7: { halign: "center" },  // Center-align Manufactured Date
  //       8: { halign: "center" },  // Center-align Expiry Date
  //       9: { halign: "center" },  // Center-align Maintenance Date
  //       10: { halign: "center" }, // Center-align Status
  //     },
  //     margin: { top: 20 },
  //   });

  //   // Save the PDF
  //   doc.save("VehiclePartDetails.pdf");
  // };

  const ExcelExport = () => {
    const csvData = tableData.map((row) => ({
      id: row.id,
      part_name: row.part_name,
      part_number: row.part_number,
      part_life_span: row.part_life_span,
      installation_date: row.installation_date,
      installing_mileage: row.installing_mileage,
      manufactured_date: row.manufactured_date,
      expiry_date: row.expiry_date,
      maintenance_date: row.maintenance_date,
      part_status: row.part_status,
    }));

    const csv = Papa.unparse(csvData);

    // Trigger file download
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "VehicleDetails.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Handler for closing dropdown and deselecting row on outside click
  const handleClickOutside = (event) => {
    if (actionsRef.current && !actionsRef.current.contains(event.target)) {
      setSelectedRow(null);
      setDropdownVisible(false);
    }
  };

  const downloadPDF = async (upload_paths) => {
    console.log(upload_paths);

    try {
      const response = await axios.get(
        `${backendUrl}/paymentEntry/downloadPaymentDocument/${upload_paths}`,
        {
          responseType: "blob",
        }
      );
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = "VehicleDatails";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("Error downloading the PDF:", error);
    }
  };

  const actionsRef = useRef(null); // Ref for the "actions" element

  // Close dropdown and deselect row if clicking outside the table
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Call the API whenever rowsPerPage or currentPage changes
  useEffect(() => {
    getTableData();
  }, [
    rowsPerPage,
    pagination.current_page,
    seletedValue,
    seletedValue.search_term,
  ]);

  // useEffect(() => {
  //   getTableData();
  // }, [seletedValue.search_term]);\

  return (
    <div className="mx-10 mt-5 text-xl">
      <div className="">
        <div className="mx-10 mt-5 -ml-1">
          <Navbar />
          <div className="flex items-center justify-between mt-6 ">
            {/* Added negative margin-left */}
            <div>
              <p className="text-[30px] font-semibold">
                Vehicle Information Management
              </p>
              <p className="text-[15px] font-semibold text-[#ABDCB0]">
                Vehicle Part Details
              </p>
            </div>
            <div className="flex items-center gap-6">
              <div>
                <div className="text-[#069855] text-[20px] font-bold">
                  {/* {currentDate} */}
                </div>
              </div>
              <div className="text-[20px] font-bold">{/* {currentTime} */}</div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:items-center md:gap-4 mt-6">
            {/* Search Box for Account Name */}
            <div className="w-full md:w-[350px]">
              <input
                type="text"
                placeholder="search vehicle part Id"
                name="search_term"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                autoComplete="off"
                // onChange={(e) => setSearchInput(e.target.value)}
                // value={seletedValue.search_term}

                // value={searchInput}
                // onChange={(e) => setSearchInput(e.target.value)}
                className="w-full p-2 border border-black rounded focus:ring-2"
              />
            </div>

            {/* Button Container */}
            <div
              className="relative flex items-center gap-4 ml-auto"
              id="actions"
              ref={actionsRef}
            >
              {/* <button
                className="flex items-center gap-2 px-4 py-2 text-white bg-gradient-to-r from-red-400 to-red-600 rounded hover:from-red-500 hover:to-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                onClick={PdfExport}
              >
                <IoAdd className="text-xl" />
                PDF Export
              </button> */}
              <button
                className="flex items-center gap-2 px-4 py-2 text-white bg-gradient-to-r from-green-400 to-green-600 rounded hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
                onClick={ExcelExport}
              >
                <IoAdd className="text-xl" />
                Excel Export
              </button>
              {selectedRow ? (
                // Action Dropdown
                <div className="relative">
                  <button
                    onClick={() => setDropdownVisible(!dropdownVisible)}
                    className="flex items-center gap-2 px-4 py-2 text-white bg-indigo-600 rounded hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    Actions
                  </button>
                  {dropdownVisible && (
                    <div className="absolute right-0 z-20 mt-2 bg-white border border-black rounded shadow-lg">
                      <button
                        onClick={handleEdit}
                        className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                      >
                        <FaEdit className="mr-2" /> Edit
                      </button>
                      <button
                        onClick={handleDelete}
                        className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                      >
                        <FaTrash className="mr-2" /> Delete
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                // Add Button
                <button
                  className="flex items-center gap-2 px-4 py-2 text-white bg-indigo-600 rounded hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={togglePopup}
                >
                  <IoAdd className="text-xl" />
                  Add Vehicle Part
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="my-4 overflow-x-auto mt-10 z-auto">
        {/* Scrollable Table Container with Increased Height */}
        <div className="max-h-[600px] overflow-y-auto">
          {/* TailwindCSS Table */}
          <table className="min-w-full bg-white border border-black ">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Part ID
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Vehicle ID
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Part Name
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Part Number
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Lifespan(km)
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Installation Date
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Manufactured Date
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Expiry Date
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Maintenance Date
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr
                  key={index}
                  className={`${
                    selectedRow === item
                      ? "bg-blue-600 text-white"
                      : "hover:bg-gray-100"
                  }`}
                  onClick={() => handleRowSelect(item)}
                >
                  <td className="px-6 py-4 border-b border-black">{item.id}</td>
                  <td className="px-6 py-4 border-b border-black">
                    {item.vehicle_id}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {item.part_name}
                  </td>

                  <td className="px-6 py-4 border-b border-black">
                    {item.part_number}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {item.part_life_span}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {item.installation_date}
                  </td>

                  <td className="px-6 py-4 border-b border-black">
                    {item.manufactured_date}
                  </td>
                  {/* <td className="px-6 py-4 border-b border-black">
                    {row.upload_paths}
                  </td> */}

                  <td className="px-6 py-4 border-b border-black">
                    {item.expiry_date}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {item.maintenance_date}
                  </td>

                  <td className="px-6 py-4 border-b border-black">
                    {item.part_status}
                  </td>

                  {/* <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap ">
                    <button
                      className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75 transition duration-300"
                      onClick={() => downloadPDF(row.upload_paths)}
                    >
                      Download PDF
                    </button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4 gap-2">
        {[20, 40, 60].map((size) => (
          <button
            key={size}
            onClick={() => handleRowsPerPageChange(size)}
            className={`px-4 py-2 ${
              rowsPerPage === size
                ? "bg-green-600 text-white" // Active button with green background and white text
                : "bg-green-300 text-black" // Inactive button with lighter green background and black text
            } rounded hover:bg-green-500`} // Hover effect for both states
          >
            {size}
          </button>
        ))}
        <button
          onClick={() =>
            setPagination((prevPagination) => ({
              ...prevPagination,
              currentPage: Math.min(
                prevPagination.currentPage + 1,
                prevPagination.totalPages
              ),
            }))
          }
          disabled={pagination.currentPage >= pagination.totalPages}
          className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600"
        >
          Load More
        </button>
      </div>

      {/* Add vehicle part Popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <AddVehiclePartPopup togglePopup={togglePopup} />
          </div>
        </div>
      )}

      {/* Edit Vehicle parts Popup */}
      {isOpenEdit.open && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <Edit_vehicle_part_popup
              toggleEditPopup={toggleEditPopup}
              vehicle={isOpenEdit?.data}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Vehicle_part_details;
