import React from "react";
import AnimatedCard from "../../../components/vehicleFleetCard/card"
import vehicleImage from '../../../Assets/vehicleFleetImg/vehicle.svg';
import driverImage from '../../../Assets/vehicleFleetImg/driver.svg'
import vehiclePartDetails from "../../../Assets/vehicleFleetImg/vehicle_parts.svg"


const Home = () => {


  return (
    <div className="flex flex-wrap justify-center items-center gap-8 p-4 bg-gray-100 min-h-screen">
      {/* Card 1 */}
      <AnimatedCard
        imageSrc= {vehicleImage}
        title="Vehicle Details"
        description="Explore the beauty of nature in this breathtaking landscape."
        buttonText="Vehicle"
        link='/vehicle-fleet-management/vehicle-details'
       
      />

      {/* Card 2 */}
      <AnimatedCard
        imageSrc={driverImage}
        title="Driver Details"
        description="Discover the fast-paced life of a vibrant city."
        buttonText="Driver"
        link='/vehicle-fleet-management/driver-details'
      />


      {/* Card 3 */}
      <AnimatedCard
        imageSrc={vehiclePartDetails}
        title="Vehicel Part Details"
        description="Feel the power of towering mountains.dhhhdfhhfff"
        buttonText="Vehicle Part Details"
        link='/vehicle-fleet-management/vehicle-part-details'  
      />

       


    </div>
  );
};

export default Home;
